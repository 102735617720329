import axios from 'axios';
import moment from 'moment';
import store from '../Store/store';
import {
  insertUserTokenExpiration
} from '../Store/Tokens/Tokens.actions';
import {insert} from '../Store/User/User.actions';

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// GET OR VALIDATE TO REFETCH TOKEN
const validateOrGetToken = async () => {
  let token = undefined;
  const currentTokensState = store.getState().tokens;

  if (!currentTokensState.userExpiration
    || moment(currentTokensState.userExpiration).isSameOrBefore(moment())
  ) {
    store.dispatch(insert({}));
    store.dispatch(insertUserTokenExpiration(undefined));
  } else {
    token = currentTokensState.user;
  }

  return `Bearer ${token}`;
};

export async function simpleRequestHandler(url, method, body = null) {
  // INITIAL STATES
  const config = {
    headers: {}
  };

  //TOKEN VALIDATION AND INSERT ON CALL
  if (url === 'usuario' || (/^usuario\/.*/.test(url) && url !== 'usuario/login_check')) {
    await validateOrGetToken()
      .then(response => {
        config.headers.Authorization = response;
      });
  }

  //METHOD VALIDATION TO PUSH PARAMS/DATA
  if (method === 'GET' && body) {
    config.params = body;
  } else {
    config.data = body;
  }

  return instance.request({
    url: url,
    method: method,
    ...config
  });
}

export default simpleRequestHandler;
