import eventTracking from './events';

const createDataLayerEvent = (properties, state) => {
  var event = {};
  var dataLayerData = {};

  if(state.pageTracking) {
    dataLayerData.page = {
      'name': state.pageTracking
    };
  }

  if(state.product) {
    dataLayerData.jornada = {
      'jornada': state.product.cardTitle
    };  
  }

  if(state.selectedProduct) {
    dataLayerData.produto = {
      'id': state.selectedProduct.id,
      'nome': state.selectedProduct.title
    };

    if(state.selectedProduct.cta) {
      dataLayerData.productCta = {
        'ref': state.selectedProduct.cta
      };
    }
  }

  
  
  properties.action && (event.event = properties.action);
  properties.action && (event.action = properties.action);
  properties.category && (event.category = properties.category);
  dataLayerData && (event.data = dataLayerData);
  
  return event;
};

const pushEventToDataLayer = (event) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: event.event,
    ...event.data,
  });
};

const createIdentifyEvent = (user) => {
  var data = {};
  
  data.cliente = {
    'id': user.id,
    'assinante': user.assinante,
    'plano': user.plano ? user.plano.nome : null
  };

  return {
    event: 'user.identify',
    data: data
  };
};

const trackEvent = (properties, state) => {

  // 1. identify user
  if(state.user.id) {
    const identifyEvent = createIdentifyEvent(state.user); 
    pushEventToDataLayer(identifyEvent);
  }

  // 2. track event
  const dataLayerEvent = createDataLayerEvent(properties, state);
  pushEventToDataLayer(dataLayerEvent);
};

const trackingMiddleware = store => next => action => {

  if (eventTracking[action.type]) {
    trackEvent(eventTracking[action.type], store.getState());
  }

  return next(action);
};

export default trackingMiddleware;