export function insert (userData) {
  return {
    type: 'INSERT_USER',
    payload: userData
  };
}

export function get () {
  return{
    type: 'GET_USER',
  };
}
