import React from 'react';
import PropTypes from 'prop-types';
import ProductCard from '../ProductCard';

import './styles.css';

SubCategorySection.propTypes = {
  subCategory: PropTypes.array
};

export default function SubCategorySection(props) {
  const { subCategory } = props;

  return (
    <div>
      {subCategory.map((item, key) => (
        <div key={key}>
          <div className="subcategoryTitleSection">
            <p className="subCategoryTitle">
              {item.nome}
            </p>
          </div>
          <div className="subCategoryCardSection">
            {item.items.map((product, key) =>
              <ProductCard
                key={key}
                item={product}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
