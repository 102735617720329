import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/youtube';
import {
  ImPlay3,
} from 'react-icons/all';
import Flickity from 'react-flickity-component';

import './styles.css';

const Videos = () => {
  const [prevSlide, setPrevSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const videos = [
    {
      id: 1,
      title: 'Seja Educador+',
      url: 'https://youtu.be/Uw_AeR_r8Bs',
      youtube_id: 'Uw_AeR_r8Bs',
    },
    {
      id: 2,
      title: 'O que é Educador+?',
      url: 'https://youtu.be/2FTgtYY49QU',
      youtube_id: '2FTgtYY49QU',
    },
    {
      id: 3,
      title: 'Educador+ & SinproSP',
      url: 'https://youtu.be/RjDCa-6j8nA',
      youtube_id: 'RjDCa-6j8nA',
    },
  ];

  const handleSlideChange = (value) => {
    if (value === 0 || value) {
      setPrevSlide(value);
      setCurrentSlide(value);
    } else {
      setCurrentSlide(prevSlide);
    }
  };

  const YoutubePlayer = ({ video }) => {
    const thumbUrl = 'https://img.youtube.com/vi/[VIDEO_ID]/0.jpg';
    const [playVideo, setPlayVideo] = useState(false);

    return (
      <div className="customPlay">
        {(playVideo) && (
          <ReactPlayer
            pip
            playing
            config={{
              youtube: {
                playerVars: {
                  rel: 0,
                  showinfo: 0,
                  controls: 1,
                  modestbranding: 1,
                },
              },
            }}
            width="100%"
            height="160px"
            url={video.url}
            stopOnUnmount={false}
            onEnded={() => setPlayVideo(false)}
          />
        )}
        {(!playVideo) && (
          <div>
            <div
              role="button"
              tabIndex={0}
              style={{ backgroundImage: `url("${thumbUrl.replace('[VIDEO_ID]', video.youtube_id)}")` }}
              className="video_thumb"
              onClick={() => {
                setPlayVideo(true);
              }}
              onKeyDown={() => {
                setPlayVideo(true);
              }}
            >
              <div className="video_thumb_overlay" />
              <ImPlay3 color="#ffffff" size={56} className="thumb_play_icon" />
            </div>
          </div>
        )}
      </div>
    );
  };

  YoutubePlayer.propTypes = {
    video: PropTypes.shape({
      url: PropTypes.string.isRequired,
      youtube_id: PropTypes.string.isRequired,
    }),
  };

  YoutubePlayer.defaultProps = {
    video: {
      url: '',
      youtube_id: '',
    },
  };

  return (
    <div className="videos_container" id="videos">
      <p className="videos_title">
        Vídeos
      </p>
      <p className="videos_description">
        Confira nossos vídeos que preparamos
        especialmente para você.
      </p>
      <Flickity
        options={{
          pageDots: false,
          dragThreshold: 20,
          cellAlign: 'center',
          // initialIndex: (window.innerWidth > 1720) ? 2 : (window.innerWidth > 1120) ? 1 : 0,
          initialIndex: 1,
        }}
        value={currentSlide}
        onChange={handleSlideChange}
      >
        {videos.map((item) => (
          <div className="video_container" key={item.id}>
            <YoutubePlayer video={item} />
            <p className="video_title">
              {item.title}
            </p>
          </div>
        ))}
      </Flickity>
    </div>
  );
};

export default Videos;
