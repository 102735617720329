export default function (state = null, action) {
  switch (action.type) {
  case 'GET_SELECTED_PRODUCT':
    return state;
  case 'INSERT_SELECTED_PRODUCT':
    return action.payload;
  case 'PRODUCT_CTA_CLICK':
    return { ...state, cta: action.payload };
  default:
    return state;
  }
}
