import React from 'react';

import dicasmei_logo from '../../../assets/logo.svg';
import dicasmei_logo_mobile from '../../../assets/logo_mobile.svg';

import './styles.css';

export default function Footer() {
  return(
    <div className={'footer_content'}>
      <div className={'footer_row'}>
        <p className={'powered_by'}>powered by</p>
        <img alt={''} className={'footer_logo'} src={dicasmei_logo} />
        <img alt={''} className={'footer_logo_mobile'} src={dicasmei_logo_mobile} />
        <span className={'footer_text'}>Todos os direitos reservados à DicasMEI @2020</span>
      </div>
    </div>
  );
}
