import React from 'react';
import {Link} from 'react-router-dom';
import {
  FaTimes
} from 'react-icons/all';

import './styles.css';

import Crown from '../../../assets/crown.svg';
import {useDispatch} from 'react-redux';
import {insertMustSubscribe} from '../../Store/MustSubscribe/MustSubscribe.actions';

export default function SubscriptionModal() {

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(insertMustSubscribe(false));
  };

  return(
    <div className={'requireSub_container'}>
      <div className={'requireSub_Modal'}>
        <FaTimes
          size={24}
          className={'requireSub_modal_close'}
          onClick={handleCloseModal}
        />
        <p className={'requireSub_modal_title'}>
          Essa vantagem é exclusiva para quem é Educador+
        </p>
        <img
          src={Crown}
          alt={''}
          className={'requireSub_modal_icon'}
        />
        <p className={'requireSub_modal_text'}>
          <b>Não é assinante?</b>
          <br />
          Não perca tempo e aproveite.
          <br />
          Confira as vantagens:
        </p>
        <div className={'requireSub_modal_buttons'}>
          <Link
            to={'/confirmar_cadastro'}
            style={{
              marginBottom: '10px'
            }}
            onClick={handleCloseModal}
            className={'requireSub_modal_button'}
          >
            Quero conferir
          </Link>
          <div className={'existingAccount_item'}>
            <p>Já é assinante?</p>
            <Link
              to={'/entrar'}
              onClick={handleCloseModal}
            >
              Faça seu login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
