import React from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Card404 from '../../../assets/card404.png';
import {
  insertSelectedProduct, viewProduct
} from '../../Store/SelectedProduct/SelectedProduct.actions';

import './styles.css';

ProductCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    featured_top: PropTypes.string,
    image: PropTypes.string,
    partner_image: PropTypes.string,
    featured_bottom: PropTypes.string,
    link: PropTypes.string,
  })
};

export default function ProductCard(props) {
  const { item } = props;

  const dispatch = useDispatch();

  return(
    <div className={'detail_card'}>
      {(item.featured_top && (
        <div className="card_highlight">
          {item.featured_top}
        </div>
      ))}
      <div className={'detail_card_image_container'}>
        <img
          alt={''}
          className={'product_card_image'}
          onError={e => {
            e.target.src = Card404;
          }}
          src={`${item.image}`}
        />
        <img
          alt={''}
          className={'card_image_partner'}
          onError={e => {
            e.target.style.display = 'none';
          }}
          src={`${item.partner_image}`}
        />
      </div>
      <div className={'product_card_text_container'}>
        <p className={'product_card_title'}>
          {item.title}
        </p>
        <p className={'product_card_description'}>
          {item.description}
        </p>
        <p className="product_card_complement">
          {item.featured_bottom}
        </p>
      </div>
      <div className={'card_link_container'}>
        <button
          style={{
            border: 'none',
            cursor: 'pointer',
          }}
          className={'card_link_button'}
          onClick={() => {
            dispatch(insertSelectedProduct(item));
            dispatch(viewProduct(item));
          }}
        >
          <p className={'card_link_button_text'}>Saiba mais</p>
        </button>
      </div>
    </div>
  );
}
