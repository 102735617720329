import React, {useEffect, useState} from 'react';
import './styles.css';

import Flickity from 'react-flickity-component';

import simpleRequestHandler from '../api/educador';

const Partners = () => {

  const [partners, setPartners] = useState([]);

  useEffect(() => {
    if (partners.length === 0) {
      simpleRequestHandler('site/parceiros', 'GET')
        .then(response => {
          setPartners(response.data);
        });
    }
  }, [partners]);

  return(
    <div className={'partner_container'}>
      <p className={'partner_title'}>Parceiros</p>
      <p className={'partner_description'}>Veja a baixo os parceiros que você pode contar</p>
      <Flickity
        className={'partner_images_container'}
        options={{
          contain: (window.innerWidth >= 820),
          initialIndex: (window.innerWidth <= 760) ? 0 : 1,
          pageDots: false,
          draggable: true,
          wrapAround: false,
          cellAlign: 'center',
          prevNextButtons: true,
        }}
      >
        {
          partners.map((partner, key) => (
            <div
              key={key}
              className={'images_content'}
              // onClick={() => window.open(`${partner.site}`, '_blank')}
            >
              <div className={'partner_image'}>
                <img alt={''} src={partner.imagem} />
              </div>
              <div className={'partner_name'}>
                <p>{partner.nome}</p>
              </div>
            </div>
          ))
        }
      </Flickity>
    </div>
  );
};

export default Partners;
