import React from 'react';
import './styles.css';

import Hearts from '../../../../assets/coração.svg';

const FamilyBenefits = () => {
  return (
    <div className={'family_benefits_container'}>
      <div className={'f_benefits_content'}>
        <img className={'hearts_icon'} src={Hearts} alt={''} />
        <div className={'f_benefits_text_container'}>
          <p className={'f_benefits_primary_text'}>
            Você e sua família merecem mais!
          </p>
          <p className={'f_benefits_secondary_text'}>
            Confira os benefícios que a Educador+ oferece para profissionais de educação em serviços de saúde, cursos, lazer, eletrônicos e muito mais!
          </p>
        </div>
      </div>
    </div>
  );
};

export default FamilyBenefits;
