const events = {
  'VIEW_PRODUCT': {
    action: 'product.view',
    category: 'discovery'
  },
  'VIEW_DEPARTMENT': {
    action: 'department.view',
    category: 'discovery'
  },
  'PAGE_VIEW': {
    action: 'page.view',
    category: 'discovery'
  },
  'PRODUCT_CTA_CLICK': {
    action: 'product.cta-click',
    category: 'selection'
  },
  'SUBSCRIPTION_PERSONAL_DATA': {
    action: 'subscription.personal-data',
    category: 'subscription'
  },
  'SUBSCRIPTION_ACCOUNT_CREATED': {
    action: 'subscription.account-created',
    category: 'subscription'
  },
  'SUBSCRIPTION_CTA_CLICK': {
    action: 'subscription.cta-click',
    category: 'subscription'
  },
  'SUBSCRIPTION_CONFIRMED': {
    action: 'subscription.confirmed',
    category: 'subscription'
  },
};

export default events;