export function insertApiToken (token) {
  return {
    type: 'INSERT_API_TOKEN',
    payload: token
  };
}

export function insertApiTokenExpiration (time) {
  return {
    type: 'INSERT_API_TOKEN_EXPIRATION',
    payload: time
  };
}

export function insertClientToken (token) {
  return {
    type: 'INSERT_USER_TOKEN',
    payload: token
  };
}

export function insertUserTokenExpiration (time) {
  return {
    type: 'INSERT_USER_TOKEN_EXPIRATION',
    payload: time
  };
}

export function getApiToken () {
  return{
    type: 'GET_API_TOKEN',
  };
}

export function getApiTokenExpiration () {
  return{
    type: 'GET_API_TOKEN_EXPIRATION',
  };
}

export function getClientTokenExpiration () {
  return{
    type: 'GET_USER_TOKEN_EXPIRATION',
  };
}
