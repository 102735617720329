export function insertSelectedProduct (product) {
  return {
    type: 'INSERT_SELECTED_PRODUCT',
    payload: product
  };
}

export function getSelectedProduct () {
  return {
    type: 'GET_SELECTED_PRODUCT',
  };
}

export function viewProduct (product) {
  return {
    type: 'VIEW_PRODUCT',
    payload: product
  };
}

export function clickProductCta (productCta) {
  return {
    type: 'PRODUCT_CTA_CLICK',
    payload: productCta
  };
}