import './styles.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Flickity from 'react-flickity-component';

import Card404 from '../../../../assets/card404.png';
import { insert } from '../../../Store/Product/Product.actions';

export default function Needing() {

  const dispatch = useDispatch();

  const cards = useSelector(state => state.products);

  const handleCardSelect = (card) => {
    dispatch(insert({
      cardTitle: card.title,
      cardBanner: card.banner,
      subcategory: card.subcategorias,
    }));
  };

  return(
    <div className={'needing_container'} id={'need'}>
      <div className={'needing_container_inner'}>
        <div className={'needing_text_container'}>
          <h3 className={'needing_title'}>
            O que você precisa ?
          </h3>
          <p className={'needing_description'}>
            Encontre aqui a melhor opção para você.
          </p>
        </div>
        <Flickity
          className={'services_container'}
          options={{
            cellAlign: 'center',
            wrapAround: false,
            pageDots: false,
            contain: true,
          }}
        >
          {
            cards.map((card, key) => (
              <div key={key} className={'service_card'}>
                <div className={'card_image'}>
                  <img
                    alt={''}
                    className={'service_image'}
                    onError={e => {
                      e.target.src = Card404;
                    }}
                    src={`${card.image}`}
                  />
                </div>
                <div className={'card_text_container'}>
                  <h4 className={'card_title'}>
                    {card.title}
                  </h4>
                  <p className={'card_description'}>
                    {card.description}
                  </p>
                </div>
                <div className={'card_button_container'}>
                  <Link
                    to={'/saibamais'}
                    className={'card_button'}
                    onClick={() => {
                      handleCardSelect(card);
                    }}
                  >
                    {card.url !== '' ? 'Saiba mais' : 'Em breve'}
                  </Link>
                </div>
              </div>
            ))
          }
        </Flickity>
      </div>
    </div>
  );
}

Needing.propTypes = {
  setInfoType: PropTypes.func
};
