import React, {useEffect, useState} from 'react';
import './styles.css';
import {
  GrClose
} from 'react-icons/all';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import BarLoader from 'react-spinners/BarLoader';
import {connect, useDispatch} from 'react-redux';
import { store } from 'react-notifications-component';
import simpleRequestHandler from '../../api/educador';
import {
  insertSelectedProduct,
  clickProductCta
} from '../../Store/SelectedProduct/SelectedProduct.actions';

ProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired
};

function ProductDetails(props) {

  const { userToken, product } = props;

  const override = css`
  width: 100%;
  height: 4px;
  margin: 0 auto;
`;

  const history = useHistory();

  const dispatch = useDispatch();

  const [attributes, setAttributes] = useState({});

  const [attributeValue, setAttributeValue] = useState(null);

  useEffect(() => {
    if (product) {
      requestCardInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (attributeValue) {
      document.getElementById(
        'attributeValue'
      ).innerHTML = `<div>${attributeValue}</div>`;
    }
  }, [attributeValue]);

  function handleModalClose()  {
    setAttributeValue(null);
    dispatch(insertSelectedProduct(null));
  }

  function requestCardInfo() {
    const requestFrom = userToken ? 'usuario' : 'site';
    simpleRequestHandler(
      `${requestFrom}/cards/${product.id}`, 'GET'
    ).then(response => {
      let productAttributes = response.data.attributes;

      Object.keys(productAttributes).map((attribute, key) => {
        productAttributes[attribute] = {
          value: productAttributes[attribute],
          selected: key === 0
        };

        if (key === 0) {
          setAttributeValue(productAttributes[attribute].value);
        }

        return attribute;
      });

      setAttributes(productAttributes);
    })
      .catch(error => {
        if (error && error.response && error.response.status === 404) {
          handleUnauthorizedAccess();
        } else {
          store.addNotification({
            title: 'Ocorreu um erro.',
            message: 'Não foi possivel exibir os detalhes do produto selecionado, por favor, verifique sua conexão e tente novamente.',
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__bounceIn'],
            animationOut: ['animate__animated', 'animate__bounceOut'],
            dismiss: {
              duration: 4000,
              onScreen: true
            }
          });
        }
      });
  }

  function handleUnauthorizedAccess() {
    store.addNotification({
      title: 'Ocorreu um erro.',
      message: 'O produto selecionado foi excluído ou você não tem permissão para visualizalo.',
      type: 'danger',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__bounceIn'],
      animationOut: ['animate__animated', 'animate__bounceOut'],
      dismiss: {
        duration: 4000,
        onScreen: true
      }
    });
    history.push('/');
  }

  function handleAttributeSelect(key) {

    let newAttributes = [];

    Object.keys(attributes).map((a, k) => {
      newAttributes[a] = {
        value: attributes[a].value,
        selected: key === k
      };

      if (key === k) {
        setAttributeValue(attributes[a].value);
      }

      return a;
    });

    setAttributes(newAttributes);
  }

  return (
    <div
      className="product-details"
      onClick={(e) => {
        if (e.target.className === 'product-details') {
          handleModalClose();
        }
      }}
    >
      <div className="product-details-inner">
        <motion.div
          key={product.id}
          exit={{ y: 500 }}
          animate={{ y: 0 }}
          initial={{ y: 500 }}
          transition={{
            damping: 30,
            type: 'spring',
            stiffness: 250
          }}
          className="product-details-content">
          <div className="product-details-heading">
            <p className="product-details-title">
              {product.title}
            </p>
            <GrClose
              className="product-details-close-icon"
              onClick={handleModalClose}
            />
          </div>
          <div className="product-details-attributes">
            {Object.keys(attributes).map((attribute, key) =>
              <div
                key={key}
                className="product-details-attribute"
                style={attributes[attribute].selected ? {
                  backgroundColor: '#c7c7c7',
                  borderBottom: 'solid 4px #e28038'
                }: {}}
                onClick={() => {
                  handleAttributeSelect(key);
                }}
              >
                <span className="product-details-attribute-name">
                  {attribute}
                </span>
              </div>
            )}
          </div>
          <BarLoader color={'#e28038'} loading={!attributeValue} css={override} />
          <div
            id='attributeValue'
            className="product-details-attribute-value"
            style={attributeValue ? {
              borderTop: 'solid 4px #e28038'
            } : {}}
          />
          <div className="product-details-footer">
            {product.featured_bottom && (
              <p className="product-details-complement">
                {product.featured_bottom}
              </p>
            )}
            {product.link ? (
              <button
                className="btn product-details-button"
                onClick={() => {
                  dispatch(clickProductCta('assine'));
                  var win = window.open(product.link, '_blank');
                  win.focus();
                }}
              >
                Aproveitar oferta
              </button>
            ) : (
              <div className="product-details-button-section">
                <b
                  className="product-details-exclusive"
                >
                  *Essa vantagem é exclusiva para quem é Educador+
                </b>
                <button
                  className={'btn product-details-button'}
                  onClick={() => {
                    history.push('/entrar');
                  }}
                >
                  Entrar
                </button>
              </div>
            )}
            <div className={'product-details-footer-info'}>
              {product.link && (
                <p className="product-details-footer-info-redirect">
                  Ao clicar você  será redirecionado para o site do parceiro
                </p>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userToken: state.tokens.user,
  product: state.selectedProduct
});

export default connect(mapStateToProps)(ProductDetails);
