import React from 'react';
import {
  FaChevronLeft
} from 'react-icons/all';
import PropTypes from 'prop-types';
import {createBrowserHistory} from 'history';

import './styles.css';

RouterHeader.propTypes = {
  routeName: PropTypes.string,
  routePath: PropTypes.string
};

export default function RouterHeader(props) {
  const { routeName, routePath } = props;

  const history = createBrowserHistory();

  return(
    <div className={'router_header_container'}>
      <div className={'router_header_back_container'} onClick={() => {
        history.back();
      }}>
        <FaChevronLeft className={'router_header_back_button'} />
        <p className={'router_header_back_text'}>
          Voltar
        </p>
      </div>
      <div className={'router_header_content'}>
        <p className={'router_header_title'}>
          {routeName}
        </p>
        { routePath && (
          <p className={'router_header_info_page'}>
            Você está em: {routePath}
          </p>
        )}
      </div>
    </div>
  );
}
