export default function (state = null, action) {
  switch (action.type) {

  case 'GET_API_TOKEN':
    return state.api;

  case 'GET_API_TOKEN_EXPIRATION':
    return state.apiExpiration;

  case 'GET_USER_TOKEN':
    return state.user;

  case 'GET_USER_TOKEN_EXPIRATION':
    return state.userExpiration;

  case 'INSERT_API_TOKEN':
    return {
      ...state,
      api: action.payload
    };

  case 'INSERT_API_TOKEN_EXPIRATION':
    return {
      ...state,
      apiExpiration: action.payload
    };

  case 'INSERT_USER_TOKEN':
    return {
      ...state,
      user: action.payload
    };

  case 'INSERT_USER_TOKEN_EXPIRATION':
    return {
      ...state,
      userExpiration: action.payload
    };

  default:
    return state;
  }

}
