import React, { useState } from 'react';
import {
  FaChevronDown
} from 'react-icons/all';
import PropTypes from 'prop-types';

import './styles.css';

QuestionCard.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default function QuestionCard(props) {

  const {
    text,
    title
  } = props;

  const [showText, setShowText] = useState(false);

  return (
    <div className={'question_card'}>
      <div
        className={'question_card_header'}
        onClick={() => setShowText(!showText)}
      >
        <p className={'question_card_title'}>
          {title}
        </p>
        <FaChevronDown
          className={'question_card_icon'}
        />
      </div>
      {(showText) && (
        <div className={'question_card_content'}>
          {text}
        </div>
      )}
    </div>
  );
}
