import React, { useEffect } from 'react';
import {
  VscGlobe,
  SiFacebook
} from 'react-icons/all';

import {
  useDispatch,
} from 'react-redux';

import './styles.css';
import AppStore from '../../../assets/disponivel-na-app-store.png';
import GooglePlay from '../../../assets/disponivel-google-play.png';

import Header from '../../global/Header';
import RouterHeader from '../../global/RouteHeader';
import Footer from '../../global/Footer';
import QuestionCard from '../../global/QuestionCard';
import { trackPageView } from '../../Store/TrackingMiddleware/TrackingMiddleware.actions';

export default function EducadorMais() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackPageView('about'));
  }, [dispatch]);

  return(
    <div className={'educador_container'}>
      <Header />
      <div className={'educador_full_container'}>
        <RouterHeader
          routeName={'O que é Educador +'}
          routePath={'Home / O que é Educador +'}
        />
        <div className={'educador_info_container'}>
          <div className={'educador_text_container'}>
            <p className={'educador_info_text'}>
              Educador+ oferece serviços essenciais que valorizam o desempenho profissional e atividades pessoais de educadores – professoras e professores de todos os níveis, sindicalizados no SinproSP.
              <br />
              <br />
              No Educador+ as professoras e os professores encontram um ambiente que proporciona acolhimento aos seus usuários em uma época em que a educação enfrenta sérios desafios no Brasil.
            </p>
          </div>
          <div className={'educador_text_container'}>
            <p className={'educador_title'}>
              O Educador+ é mais um benefício oferecido pelo SinproSP e tem tudo isto aqui
            </p>
            <div className={'cards_container'}>
              <QuestionCard
                title={'Saúde e Bem estar'}
                text={'Acesso presencial ou por telemedicina a consultas e exames especializados com até 40% de desconto.'}
              />
              <QuestionCard
                title={'Valorização do seu dinheiro'}
                text={'Com o Educador+ você está credenciado a utilizar a cooperativa de crédito Sicoob/Credicom. É como um banco, com todos os serviços de um banco, mas com juros mais baixos, sem as altas taxas dos bancos, e com distribuição de lucros no final de cada ano – afinal, como cooperado, você é também dono do banco. Garantido pelo Banco Central.'}
              />
              <QuestionCard
                title={'Clube de descontos'}
                text={'Exclusivo para educadores, com ofertas de até 40% de desconto em produtos e serviços de grandes marcas.'}
              />
              <QuestionCard
                title={'Jornada de Educação'}
                text={'Descontos exclusivos também em cursos livres, cursos de idiomas, graduação e pós-graduação, disponível para educadores e seus familiares.'}
              />
              <QuestionCard
                title={'Assistência'}
                text={'Sócios do Educador+ podem acionar o serviço a qualquer momento com questões relacionadas a seu trabalho, sua atividade profissional, seus direitos, seu salário. Auxílio jurídico especializado em direitos do educador.'}
              />
              <QuestionCard
                title={'Para sua aula'}
                text={'Dicas e informação atualizada, em formato de blog, para enriquecer a apresentação de atualidades na sua turma, preparar uma aula remota, técnicas pedagógicas.'}
              />
            </div>
          </div>
          <div className={'educador_text_container'}>
            <p className={'educador_title'}>
              Acesse Educador+ nas seguintes plataformas:
            </p>
            <div className={'educador_links_container'}>
              <a
                target="_blank"
                title="Site Educador+"
                rel="noopener noreferrer"
                href="https://educadormais.org.br"
                className={'educador_link_card'}
              >
                <VscGlobe className={'educador_link_image'} size={60} />
                <p>Site</p>
                <ul>
                  <li>
                    Compras com descontos (informática, eletrodomésticos, eletrônicos, beleza e perfumaria, saúde e bem estar).
                  </li>
                  <li>
                    Contrate serviços com descontos (seguro residencial, seguro de automóveis, motos, equipamentos portáteis, consórcios de veículos).
                  </li>
                  <li>
                    Ingressos para shows e espetáculos.
                  </li>
                  <li>
                    Programe viagens com desconto em hotéis e pacotes.
                  </li>
                  <li>
                    Novidades de Educação no Blog do Educador+.
                  </li>
                </ul>
              </a>
              <a
                target="_blank"
                title="Facebook Educador+"
                rel="noopener noreferrer"
                href="https://www.facebook.com/educadormais"
                className={'educador_link_card'}
              >
                <SiFacebook className={'educador_link_image'} size={60} />
                <p>Facebook</p>
                <ul>
                  <li>
                    Acompanhe novidades em Educação.
                  </li>
                  <li>
                    Participe de uma comunidade de educadores e mantenha-se sempre atualizado.
                  </li>
                  <li>
                    Programe viagens com desconto em hotéis e pacotes.
                  </li>
                  <li>
                    Acompanhe novidades de Educação no Blog do Educador+.
                  </li>
                  <li>
                    Novidades de Educação no Blog do Educador+.
                  </li>
                </ul>
              </a>
              <a
                target="_blank"
                title="Aplicativo Educador+"
                rel="noopener noreferrer"
                href="https://bit.ly/34pvO8F"
                className={'educador_link_card'}
              >
                <img
                  alt={''}
                  src={GooglePlay}
                  className={'educador_link_image'}
                  style={{
                    paddingTop: '12px',
                    paddingBottom: '12px'
                  }}
                />
                <p>APP Android</p>
                <ul>
                  <li>
                    Notícias do dia.
                  </li>
                  <li>
                    Agenda do professor.
                  </li>
                  <li>
                    Fala que eu te escuto.
                  </li>
                  <li>
                    Vídeos e dicas.
                  </li>
                  <li>
                    Compras com desconto, como no site Educador+.
                  </li>
                </ul>
              </a>
              <a
                target="_blank"
                title="Aplicativo Educador+"
                rel="noopener noreferrer"
                href="https://apps.apple.com/br/app/fepesp/id1475553806"
                className={'educador_link_card'}
              >
                <img
                  alt={''}
                  src={AppStore}
                  className={'educador_link_image'}
                  style={{
                    paddingTop: '12px',
                    paddingBottom: '12px'
                  }}
                />
                <p>APP IOS</p>
                <ul>
                  <li>
                    Notícias do dia.
                  </li>
                  <li>
                    Agenda do professor.
                  </li>
                  <li>
                    Fala que eu te escuto.
                  </li>
                  <li>
                    Vídeos e dicas.
                  </li>
                  <li>
                    Compras com desconto, como no site Educador+.
                  </li>
                </ul>
              </a>
            </div>
          </div>
          <div className={'educador_text_container'}>
            <p className={'educador_title'}>
              Muito bom - E onde estão os descontos?
            </p>
            <p className={'educador_info_text'}>
              Conheça o Clube de Benefícios do Educador+!
              <br />
              <br />
              Produtos e serviços, artes e espetáculos, livros e viagens, todos com descontos bons e exclusivos estão no Clube de Benefícios do Educador+.
              <br />
              <br />
              O Clube do Educador+ está aqui:
              <a
                target="_blank"
                title="Site Educador+"
                rel="noopener noreferrer"
                href="https://educadormais.org.br/"
              >
                {' https://educadormais.org.br'}
              </a>
              <br />
              <br />
              O acesso ao Clube de Benefícios do Educador+ é exclusivo para profissionais da educação.
              <br />
              <br />
              Por que login e senha? Isso é necessário apenas para que seu acesso seja único e exclusivo. Uma vez feita a adesão, basta colocar seu CPF e uma senha e pronto -  você já pode acessar todas as ofertas do seu Clube!
            </p>
          </div>
          <div className={'educador_text_container'}>
            <p className={'educador_title'}>Como usar o Educador+</p>
            <div className={'educador_info_text'}>
              Você pode acessar diretamente pelo site ou APP.
              <br/>
              <br/>
              Para fazer compras com grandes descontos, contratar serviços, utilizar a assistência médica ou a Agenda do Professor (no aplicativo), você deve usar um login e senha.
              <br/>
              <br/>
              <QuestionCard
                title={'Primeiro Acesso'}
                text={<div>
                  No seu primeiro acesso ao Clube de compras, você terá que se identificar no site do Educador+:
                  <ul>
                    <li>Faça seu cadastro com todas as informações</li>
                    <li>Receba sua senha de acesso </li>
                    <li>Efetue o pagamento</li>
                    <li>Aguarde aprovação, você receberá um e-mail de confirmação</li>
                    <li>Pronto!</li>
                  </ul>
                </div>}
              />
              <QuestionCard
                title={'Uso regular'}
                text={<div>
                  <ul>
                    <li>Acesse as ofertas e serviços exclusivos voltando ao Educador+ através do próprio site do Sinpro SP</li>
                    <li>Basta informar seu login e senha definidos no primeiro acesso para entrar no Clube de Benefícios (
                      <a
                        target="_blank"
                        title="Site Educador+"
                        rel="noopener noreferrer"
                        href="https://educadormais.org.br/"
                      >
                        https://educadormais.org.br/
                      </a>)
                    </li>
                    <li>Sua senha e login valem também para o aplicativo do seu celular. Você pode fazer compras, acompanhar notícias, consultar seus direitos, usar a agenda do professor diretamente no seu celular.</li>
                  </ul>
                </div>}
              />
            </div>
          </div>
          <div className={'educador_text_container'}>
            <p className={'educador_title'}>
              Tudo isto está no Educador+:
            </p>
            <div className={'educador_info_text'}>
              <QuestionCard
                title={'Novidades no Facebook'}
                text={<div>
                  As páginas do
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Facebook Educador+"
                    href="https://www.facebook.com/educadormais"
                  >
                    {' https://www.facebook.com/educadormais'}
                  </a> e
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Instagram Educador+"
                    href="https://www.instagram.com/educadormais"
                  >
                    {' www.instagram.com/educadormais '}
                  </a>
                  tem sempre novidades: novas ofertas, novos serviços, dicas e informações - e o bate papo com amigos e colegas na hora que quiser.
                </div>}
              />
              <QuestionCard
                title={'Compras com desconto'}
                text={<div>
                  Visite o Clube do Educador+ no app exclusivo ou através do site
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Site Educador+"
                    href="https://educadormais.org.br/"
                  >
                    {' https://educadormais.org.br/ '}
                  </a>
                  Lá você pode selecionar por:
                  <ul>
                    <li>Serviços (viagens, cultura e lazer, seguros, consórcios)</li>
                    <li> Produtos (celulares/smartphones. Informática, eletrodomésticos, beleza e perfumaria. Eletro portáteis, eletrônicos, saúde e bem estar)</li>
                    <li>Os descontos especiais e exclusivos são apresentados em cada item</li>
                    <li>Fazendo sua seleção, você será direcionado para a página do vendedor – já com a aplicação da sua vantagem como Educador+. Complete com as informações de débito, endereço e entrega – e parabéns pela compra!</li>
                  </ul>
                </div>}
              />
              <QuestionCard
                title={'Assistência Médica'}
                text={'Tenha acesso, presencial ou por telemedicina, a consultas e exames especializados com até 40% de desconto. Acessível através do seu aplicativo.'}
              />
              <QuestionCard
                title={'Assistência Jurídica'}
                text={'Não se preocupe se é tarde da noite ou fim de semana e o sindicato está fechado. O Educador+ está sempre aberto para receber sua questão. Auxílio jurídico especializado em direitos do educador para lhe ajudar a entender e se proteger quando necessário.'}
              />
            </div>
          </div>
          <p className={'educador_title'}>COOPERATIVA DE CRÉDITO</p>
          <p className={'educador_info_text'}>
            Os sócios do Educador+ são credenciados a participar de uma modalidade de instituição financeira fechada para a maioria das pessoas: as cooperativas de crédito.
            <br />
            <br />
            A cooperativa de crédito é uma forma de ajuda mútua por meio da cooperação e da parceria. Ou seja, os cooperados são os ‘donos’ do banco, e podem oferecer produtos e serviços por baixo custo e, no fim do ano, repartir o seu resultado financeiro entre os seus cooperados.
            <br />
            <br />
            Mas, para funcionar com a fiscalização e proteção do Banco Central, a cooperativa de crédito aceita apenas cooperados que pertençam a uma categoria ou a uma instituição credenciada. Por isso, pouco gente fala ou tem acesso a uma cooperativa de crédito – não é para qualquer um.
            <br />
            <br />
            <b>Participantes cadastrados no Educador+ podem ser cooperados. </b>
            <br />
            <br />
            Os cooperados da Credicom tem acesso a todos os serviços de produtos de um banco – conta corrente, cartão, aplicativo, pagamento de contas, tudo – sem as altas taxas de um banco, sem filas, e sem o aborrecimento de um banco.
            <br />
            <br />
            E, no fim do ano, os cooperados tem o rateio dos resultados da Credicom. Na mesma proporção do seu uso da cooperativa.
            <br />
            <br />
            Entre no Educador+ agora e se credencie para abandonar as amarras e o alto custo dos bancos e usufruir das vantagens de movimentar seu dinheiro como se você fosse o próprio dono do seu banco.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
