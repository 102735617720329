import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {insert} from '../../Store/User/User.actions';
import simpleRequestHandler from '../../api/educador';
import {AnimatePresence, motion} from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { VscAccount, FaChevronDown } from 'react-icons/all';
import {insert as insertProduct} from '../../Store/Product/Product.actions';
import {
  insertClientToken,
  insertUserTokenExpiration
} from '../../Store/Tokens/Tokens.actions';

import './styles.css';

import logo from '../../../assets/educador.png';

const Header = () => {

  const dispatch = useDispatch();

  const user = useSelector(state => state.user);

  const userToken = useSelector(state => state.tokens.user);

  const userTokenExpiration = useSelector(state => state.tokens.userExpiration);

  const [showMenu, setShowMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const menuItens = useSelector(state => state.products);

  useEffect(() => {
    if (userTokenExpiration) {
      simpleRequestHandler('usuario', 'GET')
        .then(({ data }) => {
          dispatch(insert(data));
          localStorage.setItem('user', JSON.stringify(data));
        })
        .catch(error => console.log(error));
    } else {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTokenExpiration]);

  const handleCloseMenus = () => {
    setShowMenu(false);
  };

  const handleMenuClick = (e) => {
    setShowMenu(!showMenu);
    if (e.currentTarget.classList.contains('open')) {
      e.currentTarget.classList.remove('open');
    } else {
      e.currentTarget.classList.add('open');
    }
  };

  const handleLogout = () => {
    dispatch(insertClientToken(undefined));
    dispatch(insertUserTokenExpiration(undefined));
  };

  const handleForceLogout = () => {
    dispatch(insert({}));
    localStorage.removeItem('user');
    localStorage.removeItem('userToken');
    dispatch(insertUserTokenExpiration(undefined));
  };

  const handleCardSelect = (card) => {
    dispatch(insertProduct({
      cardTitle: card.title,
      cardBanner: card.banner,
      subcategory: card.subcategorias
    }));
  };

  return (
    <div className={'header_container'}>
      <div className={'header_content'}>
        <Link to={'/'}>
          <img src={logo} className={'header_logo'} alt={''} />
        </Link>
        <div className={'menu_content'}>
          <Link
            to={'/educador+'}
            className={'menu_link'}
          >
            O que é Educador+ ?
          </Link>
          <a
            target='_blank'
            className={'menu_link'}
            rel='noopener noreferrer'
            href='https://blog.educadormais.org.br/'
          >
            Blog
          </a>
          {(menuItens.length >= 1) && menuItens.map((item, key) => (
            <div className={'menu_link_content'} key={key}>
              <Link
                to={'/saibamais'}
                className={'menu_link'}
                onClick={() => {
                  handleCardSelect(item);
                }}
              >
                {item.title}
              </Link>
            </div>
          ))}
        </div>
        <div
          className={'account_items'}
          onMouseEnter={() => setShowUserMenu(true)}
          onMouseLeave={() => setShowUserMenu(false)}
        >
          {(userToken && user && Object.keys(user).length > 0) ? (
            <>
              <div className={'account_user_item'}>
                <VscAccount
                  size={30}
                  color={'#e28038'}
                  className={'account_user_image'}
                />
                <div className={'account_user_content'}>
                  <p className={'account_user_name'}>
                    {user.nome}
                  </p>
                  <FaChevronDown
                    size={14}
                    color={'#e28038'}
                    className={'account_user_drop_icon'}
                  />
                </div>
              </div>
              <AnimatePresence>
                {showUserMenu && (
                  <motion.div
                    initial={{
                      y: '-20px',
                      opacity: 0
                    }}
                    exit={{
                      y: '-10px',
                      opacity: 0
                    }}
                    animate={{
                      y: -35,
                      opacity: 1
                    }}
                    className={'account_user_menu'}
                  >
                    <p
                      className={'account_user_menu_item'}
                    >
                      Você já é um Educador+!
                    </p>
                    <p
                      className={'account_user_menu_item'}
                      onClick={handleForceLogout}
                    >
                      Sair
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </>
          ) : (
            <Link
              to={'/entrar'}
              className={'account_link'}
            >
              Entrar
            </Link>
          )}
        </div>
      </div>
      <div className={'mobile_header'}>
        <div className="topnav">
          <div className="hamburger_menu" onClick={handleMenuClick}>
            <span/>
            <span/>
            <span/>
          </div>
          <span
            onClick={() => handleCloseMenus()}
            className={'mobile_header_logos'}
          >
            <Link to={'/'}>
              <img
                alt={''}
                src={logo}
                className={'logo_mobile'}
              />
            </Link>
          </span>
          {(
            <div className={'topnav_itens'} style={{
              maxHeight: showMenu ? '1000px' : '0',
              transform: `scaleY(${showMenu ? 1 : 0})`
            }}>
              <Link
                to={'/educador+'}
                className={'mobile_menu_item'}
              >
                O que é Educador+ ?
              </Link>
              <a
                target='_blank'
                className={'mobile_menu_item'}
                rel='noopener noreferrer'
                href='https://blog.educadormais.org.br/'
              >
                Blog
              </a>
              {(menuItens.length >= 1) && menuItens.map((item, key) => (
                <Link
                  key={key}
                  to={'/saibamais'}
                  className={'mobile_menu_item'}
                  onClick={() => {
                    handleCardSelect(item);
                  }}
                >
                  {item.title}
                </Link>
              ))}
              {(userToken && user && user.nome) ? (
                <>
                  <div className={'mobile account_user_item'}>
                    <div
                      onClick={() => setShowUserMenu(!showUserMenu)}
                      className={'account_user_content account_user_content_m'}
                    >
                      <p className={'mobile account_user_name'}>
                        {user.nome}
                      </p>
                      <motion.div
                        animate={{
                          rotateX: showUserMenu ? 180 : 0
                        }}
                      >
                        <FaChevronDown
                          size={14}
                          color={'#e28038'}
                          className={'account_user_drop_icon'}
                        />
                      </motion.div>
                    </div>
                    {(showUserMenu) && (
                      <div className={'account_user_menu_mobile'}>
                        <p
                          className={'account_user_menu_item'}
                        >
                          Você já é um Educador+!
                        </p>
                        <p
                          onClick={handleForceLogout}
                          className={'account_user_menu_item'}
                        >
                          Sair
                        </p>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <Link
                  to={'/entrar'}
                  style={{borderTop: 'solid 1px #e28038'}}
                  className={'account_link account_link_mobile'}
                >
                  Entrar
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
