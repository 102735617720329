import React, { useEffect } from 'react';
import {
  Link,
  Redirect
} from 'react-router-dom';
import {
  FaChevronLeft
} from 'react-icons/all';
import {
  AnimatePresence,
  motion
} from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import Dictionary from './DetailsDictionary';

import './styles.css';
import Header from '../../global/Header';
import Footer from '../../global/Footer';

import Shape from '../../../assets/forma_azul.svg';
import SubCategorySection from '../../global/SubCategorySection';
import ProductDetails from '../../global/ProductDetails';

import { viewDepartment } from '../../Store/Product/Product.actions';

const MoreDetails = () => {
  const dispatch = useDispatch();

  window.scrollTo(0, 0);

  const product = useSelector(state => state.product);

  const selectedProduct = useSelector(state => state.selectedProduct);

  useEffect(() => {
    dispatch(viewDepartment());
  }, [dispatch, product]);

  if (!product) {
    return (
      <Redirect from={'saibamais'} to={'/'} exact />
    );
  } else {
    return (
      <motion.div
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        exit={{
          opacity: 0
        }}
      >
        <Header />
        <div className={'detail_container'}>
          <div
            className={'detail_banner_container'}
            style={{backgroundImage: 'url(' + product.cardBanner + ')'}}
          >
            <div
              className={'detail_banner_title_container'}
              style={{backgroundImage: 'url(' + Shape + ')'}}
            >
              <p className={'detail_banner_title'}>
                {product.cardTitle}
              </p>
            </div>
          </div>
          <div className={'cards_container'}>
            <div className={'detail_title_container'}>
              <Link to={'/'} className={'detail_back_button'}>
                <FaChevronLeft className={'detail_back_icon'} size={'10px'} />
                Voltar
              </Link>
              <p className={'detail_cards_question'}>
                {Dictionary.question.replace('[type]', product.cardTitle)}
              </p>
            </div>
            <p className={'details_cards_description'}>
              {Dictionary.description}
            </p>
            <div className={'cards_content'}>
              <SubCategorySection
                subCategory={product.subcategory}
              />
            </div>
          </div>
        </div>
        <Footer />
        {selectedProduct && (
          <AnimatePresence>
            <ProductDetails />
          </AnimatePresence>
        )}
      </motion.div>
    );
  }
};

export default MoreDetails;
