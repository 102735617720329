import React from 'react';
import ReactGA from 'react-ga';
import {
  Route,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import ReactNotification from 'react-notifications-component';

import Home from '../pages/Home';
import Login from '../pages/Login';
import MoreDetails from '../pages/MoreDetails';
import EducadorMais from '../pages/Educador+';
import SubscriptionModal from '../global/SubscriptionModal';

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname + location.hash });
  ReactGA.pageview(location.pathname + location.hash);
});

const Routes = () => {

  const mustSubscribe = useSelector(state => state.mustSubscribe);

  return(
    <Router>
      <ReactNotification />
      {mustSubscribe && (
        <SubscriptionModal />
      )}
      <Switch>
        <Route path={'/educador+'} exact>
          <EducadorMais />
        </Route>
        <Route path={'/entrar'} exact>
          <Login />
        </Route>
        <Route path={'/saibamais'} exact>
          <MoreDetails />
        </Route>
        <Route path={'/'} exact>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

export default (Routes);
