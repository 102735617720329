import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import store, { persistor } from './components/Store/store';
import {
  Provider
} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import './index.css';
import 'animate.css/animate.min.css';
import 'flickity/dist/flickity.min.css';
import 'react-notifications-component/dist/theme.css';

import Routes from './components/Routes';

import TagManager from 'react-gtm-module';

ReactGA.initialize('UA-112487562-12');
ReactGA.pageview(window.location.pathname + window.location.hash);
 
const tagManagerArgs = {
  gtmId: 'GTM-WNHJZ7Q'
};
 
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
