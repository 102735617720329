import {
  combineReducers
} from 'redux';
import {
  configureStore
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

import UserReducer from './User/User.reducer';
import TokensReducer from './Tokens/Tokens.reducer';
import ProductReducer from './Product/Product.reducer';
import ProductsReducer from './Products/Products.reducer';
import SubscribeReducer from './Subscribe/Subscribe.reducer';
import MustSubscribeReducer from './MustSubscribe/MustSubscribe.reducer';
import SelectedProductReducer from './SelectedProduct/SelectedProduct.reducer';
import TrackingMiddlewareReducer from './TrackingMiddleware/TrackingMiddleware.reducer';

import trackingMiddleware from './TrackingMiddleware/tracking.middleware';

const root = combineReducers({
  user: UserReducer,
  tokens: TokensReducer,
  product: ProductReducer,
  products: ProductsReducer,
  subscribe: SubscribeReducer,
  mustSubscribe: MustSubscribeReducer,
  selectedProduct: SelectedProductReducer,
  pageTracking: TrackingMiddlewareReducer
});

const persistConfig = {
  storage,
  key: 'root',
  timeout: 3600,
  blacklist: ['subscribe', 'mustSubscribe']
};

const initialState = {
  user: {},
  tokens: {
    api: undefined,
    user: undefined,
    apiExpiration: undefined,
    userExpiration: undefined
  },
  products: [],
  subscribe: false,
  product: undefined,
  mustSubscribe: false,
  selectedProduct: null,
};

const persistedReducer = persistReducer(persistConfig, root);

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(trackingMiddleware),
});

export const persistor  = persistStore(store);

export default store;
