import React, { useEffect, useState } from 'react';
import {
  RiInstagramLine
} from 'react-icons/all';
import axios from 'axios';

import './styles.css';

export default function InstagramFeed() {
  const [instagramToken, setInstagramToken] = useState(null);

  useEffect(() => {
    if (!instagramToken) {
      axios.get('https://ig.instant-tokens.com/users/3e08f7ff-3c9c-4c85-bfa9-7db44b717d79/instagram/17841433102573128/token?userSecret=kqzl1ud2i26lptkvc12gy')
        .then(({data}) => {
          setInstagramToken(data.Token);
          const feed = new window.Instafeed({
            limit: 3,
            accessToken: data.Token,
            template: '<a href="{{link}}" class="instagram_post_card">' +
                '<img class="instagram_post_image" src="{{image}}" alt="" />' +
              '</a>'
          });
          feed.run();
        });
    }
  }, [instagramToken]);

  return(
    <div className={'instagram_feed_container'}>
      {(instagramToken) && (
        <a
          target='_blank'
          rel="noopener noreferrer"
          href="https://www.instagram.com/educadormais"
          className={'instagram_profile_content'}
        >
          <div className={'instagram_message_content'}>
            <RiInstagramLine
              size={100}
              color={'#062b31'}
            />
            <div className={'instagram_text_container'}>
              <p className={'instagram_text_title'}>
                Confira nosso Instagram @educadormais
              </p>
              <p className={'instagram_text_description'}>
                Confira nossos conteúdos e tenha ainda mais vantagens!
              </p>
            </div>
          </div>
          <div className={'instagram_media_content'} id="instafeed" />
        </a>
      )}
    </div>
  );
}
