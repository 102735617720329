export function insert (products) {
  return {
    type: 'INSERT_PRODUCTS',
    payload: products
  };
}

export function get () {
  return {
    type: 'GET_PRODUCTS',
  };
}
