import React, {useEffect, useState} from 'react';
import {
  motion
} from 'framer-motion';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { filter } from 'lodash';
import { BarLoader } from 'react-spinners';
import {simpleRequestHandler} from '../../api/educador';
import {insert} from '../../Store/Products/Products.actions';
import {
  insertSelectedProduct
} from '../../Store/SelectedProduct/SelectedProduct.actions';

import Needing from './Needing';
import Benefits from './Benefits';
import Partners from '../../Partners';
import Header from '../../global/Header';
import Footer from '../../global/Footer';
import FamilyBenefits from './FamilyBenefits';
import InstagramFeed from '../InstagramFeed';
import Videos from '../../global/Videos';
import { trackPageView } from '../../Store/TrackingMiddleware/TrackingMiddleware.actions';

function Home() {
  const dispatch = useDispatch();
  const cards = useSelector(state => state.products);
  const userToken = useSelector(state => state.tokens.user);

  const [data, setData] = useState(cards);


  useEffect(() => {
    dispatch(trackPageView('home'));
  }, [dispatch]);

  useEffect(() => {
    if (userToken) {
      requestCards('usuario');
    } else {
      requestCards('site');
    }
    dispatch(insertSelectedProduct(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const requestCards = (from = 'site') => {
    simpleRequestHandler(`${from}/cards`, 'GET')
      .then(response => {
        setData(response.data);
        dispatch(
          insert(
            filter(response.data.cards, ['exibir_home', true])
          )
        );
      });
  };

  return(
    <motion.div
      initial={{
        opacity: 0
      }}
      animate={{
        opacity: 1
      }}
      exit={{
        opacity: 0
      }}
      className="App"
    >
      {(!data) ? (
        <BarLoader
          size={150}
          width={'100%'}
          color='#e28038'
          loading={!data}
        />
      ) : (
        <div className={'header_top_line'} />
      )}
      <Header />
      <Benefits />
      <FamilyBenefits />
      <Needing />
      <Videos />
      <Partners />
      <InstagramFeed />
      <Footer />
    </motion.div>
  );
}

export default Home;
