import moment from 'moment';
import React, {useState} from 'react';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { cpf as cpf_utils } from 'cpf-cnpj-validator';
import {simpleRequestHandler} from '../../api/educador';
import {
  insertClientToken,
  insertUserTokenExpiration
} from '../../Store/Tokens/Tokens.actions';

import '../forms.css';

import Header from '../../global/Header';
import Footer from '../../global/Footer';
import RouterHeader from '../../global/RouteHeader';

const Login = () => {
  const [cpf, setCpf] = useState('');
  const [matricula, setMatricula] = useState('');

  const history = useHistory();

  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    if (cpf === '' || matricula === '') {
      store.addNotification({
        title: 'CPF ou matricula inválidos',
        message: 'Você precisa preencher seu CPF e matricula.',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__bounceIn'],
        animationOut: ['animate__animated', 'animate__bounceOut'],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
    } else if (!cpf_utils.isValid(cpf)) {
      store.addNotification({
        title: 'CPF inválido',
        message: 'Você precisa digitar um CPF válido.',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__bounceIn'],
        animationOut: ['animate__animated', 'animate__bounceOut'],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
    } else {
      simpleRequestHandler('usuario/login_check', 'POST', {
        username: cpf,
        password: matricula
      })
        .then(({ data }) => {
          dispatch(insertClientToken(data.token));
          dispatch(insertUserTokenExpiration(
            moment().add(1, 'h').format()
          ));
          store.addNotification({
            title: 'Logado',
            message: 'Logado com sucesso! Aproveite já suas vantagens!',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__bounceIn'],
            animationOut: ['animate__animated', 'animate__bounceOut'],
            dismiss: {
              duration: 4000,
              onScreen: true
            }
          });
          history.push('/');
        })
        .catch(() => {
          store.addNotification({
            title: 'Ops!',
            message: 'Ocorreu um erro ao realizar a autenticação, verifique seu ' +
              'CPF e/ou Matricula e tente novamente.',
            type: 'warning',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__bounceIn'],
            animationOut: ['animate__animated', 'animate__bounceOut'],
            dismiss: {
              duration: 4000,
              onScreen: true
            }
          });
        });
    }
  };

  return (
    <div>
      <Header />
      <div className='login_container'>
        <RouterHeader
          routeName={'Login'}
        />
        <div className={'login_container_header_message'}>
          <p>
            Para ter acesso a todos benefícios, entre com seu CPF e Matrícula SINPRO
          </p>
        </div>
        <div className={'form_container'}>
          <div className={'form_input_container'}>
            <label className={'form_label'}>CPF</label>
            <input
              className={'form_input'}
              value={cpf}
              onChange={(e) => {
                setCpf(cpf_utils.format(e.target.value));
              }}
            />
          </div>
          <div className={'form_input_container'}>
            <label className={'form_label'}>Matrícula SINPRO</label>
            <input
              className={'form_input'}
              value={matricula}
              onChange={(e) => {
                setMatricula(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={'form_buttons_container'}>
          <motion.button
            className={'form_login'}
            whileTap={{
              scale: 0.9
            }}
            onClick={handleLogin}
          >
            Entrar
          </motion.button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
