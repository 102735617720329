import React from 'react';
import './styles.css';
import Flickity from 'react-flickity-component';
import { useDispatch, useSelector } from 'react-redux';
import { insert } from '../../../Store/Product/Product.actions';
import BannerOriginal from '../../../../assets/banner-original.png';

import { Link } from 'react-router-dom';

const Benefits = () => {
  const dispatch = useDispatch();

  const cards = useSelector(state => state.products);

  const cardFinanceiro = cards.filter(card => {
    return card.title === 'Financeiro';
  })[0];

  return (
    <div className="benefits_container_controller">
      <Flickity
        className={'benefits_home_carousel'}
        options={{
          imagesLoaded: true,
          percentPosition: false,
        }}
      >
        <Link
          to={'/saibamais'}
          className={'benefits_container'}
          onClick={() => {
            dispatch(insert({
              cardTitle: cardFinanceiro.title,
              cardBanner: cardFinanceiro.banner,
              subcategory: cardFinanceiro.subcategorias,
            }));
          }}
        >
          <img className={'benefits_container_image'} src={BannerOriginal} alt={''} />
        </Link>
      </Flickity>
    </div>
  );
};

export default Benefits;
