export function insert (products) {
  return {
    type: 'INSERT_PRODUCT',
    payload: products
  };
}

export function get () {
  return {
    type: 'GET_PRODUCT',
  };
}

export function viewDepartment () {
  return {
    type: 'VIEW_DEPARTMENT'
  };
}
