export function insertMustSubscribe (option) {
  return {
    type: 'INSERT_MUST_SUBSCRIBE',
    payload: option
  };
}

export function getMustSubscribe () {
  return {
    type: 'GET_MUST_SUBSCRIBE',
  };
}
