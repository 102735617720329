export default function (state = null, action) {
  switch (action.type) {
  case 'GET_USER':
    return state;
  case 'INSERT_USER':
    return action.payload;
  default:
    return state;
  }
}
